:root {
  --tram: #f0be32;
  --trolleybus: #1e9641;
  --bus: #cd2837;
  --notice-success: #4caf50;
  --notice-error: #f44336;
  --indentation-first: 10px;
  --indentation-second: 15px;
  --indentation-third: 20px;
  --danger: #d7504b;
  --safety: #5fb962;
  --gray-a: #dcdcdc;
  --gray-b: #b3b3b3;
  --gray-c: #8c8c8c;
  --gray-d: #efefef;
  --black-a: #181818;
  --black-b: #17171c;
  --light-blue: #4fabc9;
  --border-radius-form: 4px;
  --border-radius-dialog: 8px;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  html, input, textarea {
    color: #fff;
    background-color: var(--black-a);
  }

  .tag {
    color: var(--black-a);
    background-color: var(--gray-b);
  }

  .notice {
    background-color: #333;
  }
}

@media (prefers-color-scheme: light) {
  html, input, textarea {
    color: #000;
    background-color: #fff;
  }

  .tag {
    color: #000;
    background-color: var(--gray-a);
  }

  .notice {
    background-color: #f4f4f4;
  }
}

body {
  margin-top: var(--indentation-first);
  margin-left: var(--indentation-first);
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}

body, input, textarea, button {
  font: 20px / 1.5 Segoe UI, -apple-system, BlinkMacSystemFont, Ubuntu, DroidSans, Tahoma, Roboto;
}

main {
  width: 100%;
  height: 100%;
}

h1, h2, h3 {
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  margin-bottom: 8px;
}

input, textarea {
  border-radius: var(--border-radius-form);
}

input:not([type="file"]), textarea {
  border: 2px solid var(--gray-b);
}

textarea {
  resize: vertical;
  font-size: 16px;
}

input[type="file"] {
  cursor: pointer;
}

.traction span {
  align-items: center;
  display: flex;
}

.traction h2 {
  text-decoration: underline;
  display: inline-block;
}

.traction img {
  height: 45px;
}

.model {
  margin-left: var(--indentation-second);
}

.vehicle-tags {
  flex-flow: wrap;
  gap: 12px;
  max-width: 1200px;
  display: flex;
}

.tag {
  cursor: pointer;
  border-radius: 10px;
  padding: 3px 6px;
}

.dark {
  filter: brightness(80%);
}

.dim {
  opacity: .4;
}

img {
  user-select: none;
  border-style: none;
  display: inline;
}

.filter {
  align-items: center;
  gap: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  display: flex;
}

.filter input {
  max-width: 60px;
}

.vehicle-metadata, .title {
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.vehicle-metadata img, .title img {
  height: 27px;
  margin-right: 2.5px;
}

.vehicle-metadata label, .title label {
  margin-left: 2.5px;
}

.vehicle-anchors {
  flex-flow: wrap;
  justify-content: space-around;
  display: flex;
}

.filters-view {
  margin-bottom: 15px;
}

.filters-view button {
  margin-top: 5px;
  display: inline-block;
}

.buttons {
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.buttons button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.danger {
  color: var(--danger);
}

.safety {
  color: var(--safety);
}

.svg {
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  display: inline-block;
}

.disclaimer {
  margin-top: 15px;
  font-size: 16px;
  font-style: italic;
}

.invisible {
  display: none;
}

.notice {
  border-radius: 8px;
  max-width: 60vw;
  margin: 20px 0;
  padding: 20px;
  box-shadow: 0 2px 8px #0000001a;
}

.notice.success {
  border-left: 6px solid var(--notice-success);
}

.notice.error {
  border-left: 6px solid var(--notice-error);
}

.notice-title {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.2rem;
  font-weight: bold;
}

.notice-text {
  font-size: 1rem;
}

@media screen and (width < 500px) {
  .notice {
    max-width: 80vw;
  }
}

.blog-entries {
  margin-top: 25px;
}

.blog-entry {
  margin-top: 20px;
}
/*# sourceMappingURL=index.c1802a64.css.map */
