:root {
  --tram: #f0be32;
  --trolleybus: #1e9641;
  --bus: #cd2837;

  --notice-success: #4caf50;
  --notice-error: #f44336;

  --indentation-first: 10px;
  --indentation-second: 15px;
  --indentation-third: 20px;

  --danger: #d7504b;
  --safety: #5fb962;

  --gray-a: #dcdcdc;
  --gray-b: #b3b3b3;
  --gray-c: #8c8c8c;
  --gray-d: #efefef;

  --black-a: #181818;
  --black-b: #17171c;

  --light-blue: #4fabc9;

  --border-radius-form: 4px;
  --border-radius-dialog: 8px;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  html,
  input,
  textarea {
    color: white;
    background-color: var(--black-a);
  }

  .tag {
    color: var(--black-a);
    background-color: var(--gray-b);
  }

  .notice {
    background-color: #333;
  }
}

@media (prefers-color-scheme: light) {
  html,
  input,
  textarea {
    color: black;
    background-color: white;
  }

  .tag {
    color: black;
    background-color: var(--gray-a);
  }

  .notice {
    background-color: #f4f4f4;
  }
}

body {
  margin-top: var(--indentation-first);
  margin-left: var(--indentation-first);
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}

body,
input,
textarea,
button {
  font: 20px/1.5 Segoe UI, -apple-system, BlinkMacSystemFont, Ubuntu, DroidSans, Tahoma, Roboto;
}

main {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  margin-bottom: 8px;
}

input,
textarea {
  border-radius: var(--border-radius-form);
}

input:not([type="file"]),
textarea {
  border: 2px solid var(--gray-b);
}

textarea {
  font-size: 16px;
  resize: vertical;
}

input[type="file"] {
  cursor: pointer;
}

.traction span {
  display: flex;
  align-items: center;
}

.traction h2 {
  text-decoration: underline;
  display: inline-block;
}

.traction img {
  height: 45px;
}

.model {
  margin-left: var(--indentation-second);
}

.vehicle-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 12px;
  max-width: 1200px;
}

.tag {
  cursor: pointer;

  padding-left: 6px;
  padding-right: 6px;

  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 10px;
}

.dark {
  filter: brightness(80%);
}

.dim {
  opacity: 0.4;
}

img {
  user-select: none;
  border-style: none;
  display: inline;
}

.filter {
  align-items: center;
  display: flex;

  gap: 5px;

  margin-top: 3px;
  margin-bottom: 3px;
}

.filter input {
  max-width: 60px;
}

.vehicle-metadata,
.title {
  display: flex;
  align-items: center;

  margin-bottom: 8px;
}

.vehicle-metadata img,
.title img {
  height: 27px;
  margin-right: 2.5px;
}

.vehicle-metadata label,
.title label {
  margin-left: 2.5px;
}

.vehicle-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.filters-view {
  margin-bottom: 15px;
}

.filters-view button {
  display: inline-block;
  margin-top: 5px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.buttons button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.danger {
  color: var(--danger);
}

.safety {
  color: var(--safety);
}

.svg {
  line-height: 1.5em;
  width: 1.5em;
  height: 1.5em;

  display: inline-block;

  vertical-align: middle;
}

.disclaimer {
  margin-top: 15px;
  font-size: 16px;
  font-style: italic;
}

.invisible {
  display: none;
}

.notice {
  max-width: 60vw;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 0.1);
}

.notice.success {
  border-left: 6px solid var(--notice-success);
}

.notice.error {
  border-left: 6px solid var(--notice-error);
}

.notice-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 8px;
}

.notice-text {
  font-size: 1rem;
}

@media screen and (width < 500px) {
  .notice {
    max-width: 80vw;
  }
}

.blog-entries {
  margin-top: 25px;
}

.blog-entry {
  margin-top: 20px;
}
